.camera_container {
    position: relative;
    width: 480px;
    height: 480px;
    border: #000000 1px solid;
}

.input_video {
    position: absolute;
    width: 480px;
    height: 480px;
}


.output_canvas {
    position: absolute;
    width: 480px;
    height: 480px;
    background-color: #FFFFFF;
}


.mask_canvas {
    position: relative;
    width: 480px;
    height: 480px;
    /*margin-left: -80px
    display: none;*/
}


.bb_canvas {
    display: none;
    width: 96px;
    height: 96px;
}

video {
    object-fit: fill;
    width: 480px;
    height: 480px;
}


