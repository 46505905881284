.grid-container {
    display: grid;
    column-gap: 3px;
    grid-template-columns: auto 640px auto;
    padding: 0px;
  }

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding: 0px;
    font-size: 15px;
    text-align: center;
}

.hide {
    display: none;
}


.container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
}
    


.camera {
    width: 480px;
    height: 480px;
    z-index: 10;
    
}


.percent {
    z-index: 11;
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    top: 43px;
    margin-left: -66px;
}

.message {
    z-index: 12;
    position: absolute;
    font-size: 14px;
    color: #FFFFFF;
    bottom: 10px
}


button {
    width: 230px;
    height: 45px;
    margin: 3px;
}

.nome {
    width: 460px;
    height: 45px;
    font-size: 20px;
    padding:3px
}